import React, { useState } from "react"
import logo from "./../../images/logo.png"
import { navigate } from "gatsby"
import is from "is_js"
import { getFirebase } from "../firebase"
import success from "./../../images/success.png"
import errorImg from "./../../images/error.png"
import lost from "./../../images/404.png"
const LostPasswordForm = () => {
  const [emailAddress, setEmailAddress] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState("none")
  const [errorMessage, setErrorMessage] = useState("")
  return (
    <>
      <div className="sign-in-form-container">
        <div
          style={{
            maxWidth: "75px",
            // margin: "20px auto",
            marginBottom: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/")
          }}
        >
          <img src={logo} alt="" />
        </div>
        <h3
          // align="center"
          className="title is-4 font-lato-600"
          style={{
            color: "#4a4a4a",
          }}
        >
          Forgot your password?
        </h3>
        <hr className="hr-branded"></hr>
        {state === "success" && (
          <div style={{ maxWidth: "340px", margin: "0 auto" }}>
            <img src={success} alt="success" />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Password reset email sent. Please check your inbox.
            </p>
          </div>
        )}
        {state === "error" && (
          <div style={{ maxWidth: "300px", margin: "0 auto" }}>
            <img src={errorImg} alt="error" />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {errorMessage}
            </p>
          </div>
        )}
        {state === "none" && (
          <div style={{ maxWidth: "440px", margin: "0 auto" }}>
            <div style={{ marginBottom: "15px" }}>
              <img src={lost} alt="" />
            </div>
            <p
              style={{
                marginBottom: "20px",
                textAlign: "justify",
                fontSize: "16px",
              }}
            >
              To reset your password, type the email address you used to sign up
              at voyayge and we'll send you an e-mail to walk you through
              resetting your password.
            </p>
            <div className="field">
              <p className="">Email</p>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder=""
                  onChange={e => {
                    setError(false)
                    setEmailAddress(e.currentTarget.value)
                  }}
                ></input>
              </div>
              {error && (
                <p className="help is-danger">
                  Please enter a valid email address
                </p>
              )}
            </div>
            <div className="field">
              <div
                className="control is-expanded"
                style={{ marginBottom: "10px" }}
              >
                <button
                  style={{ width: "100%", height: "40px" }}
                  className={`${
                    loading
                      ? "button is-warning is-loading"
                      : "button is-warning"
                  }`}
                  onClick={() => {
                    setLoading(true)
                    console.log({ email: emailAddress })
                    if (is.email(emailAddress)) {
                      setError(false)
                      getFirebase()
                        .auth()
                        .sendPasswordResetEmail(emailAddress)
                        .then(function() {
                          console.log("Email sent")
                          setState("success")
                          setEmailAddress("")
                          setLoading(false)
                        })
                        .catch(function(error) {
                          setState("error")
                          console.log({ error })
                          if (error.code === "auth/user-not-found") {
                            setErrorMessage(
                              "There is not user record corresponding to this email address."
                            )
                          } else {
                            setErrorMessage(
                              "There was a problem sending password reset link, try again in sometime. If the issue persists, please reach out to support@voyayge.com"
                            )
                          }
                          setEmailAddress("")
                          setLoading(false)
                        })
                    } else {
                      setError(true)
                      setLoading(false)
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="landscape"></div>
    </>
  )
}

export default LostPasswordForm
