/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, pageTitle, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            classification
          }
        }
      }
    `
  )
  console.log({ description })
  const metaDescription = description
    ? description
    : site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      // author={`${site.siteMetadata.author}`}
      // titleTemplate={`voyayge | ${site.siteMetadata.title}`}
      // titleTemplate={`${pageTitle} | ${site.siteMetadata.title}`}
      titleTemplate={`${pageTitle} - ${site.siteMetadata.title}`}
      meta={[
        // {
        //   name: `author`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `publisher`,
        //   content: `voyayge`,
        // },

        {
          name: `keywords`,
          content: `voyage,travel,travel planning,planned travel,travel itineraries,travel itinerary,voyayge,voyage,govoyayge,govoyage,travel,custom travel plan,bespoke travel plans,custom travel plans,travel designers, curated, crafted`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `theme-color`,
          content: `#fec600`,
        },
        {
          name: `classification`,
          content: `${site.siteMetadata.classification}`,
        },
        {
          property: `og:title`,
          content: `${pageTitle} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.image}`,
          itemprop: "image",
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `twitter:image`,
          content: `${site.siteMetadata.image}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: `@voyayge`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Corporation",
          "url": "https://www.voyayge.com",
          "name": "Voyayge",
          "legalName": "Voyayge Inc",
          "logo": "https://www.voyayge.com/static/logoIcon-c773ba77b8bffd22ee72c3df2f1c71c7.png",
          "description": "Get a complete day-to-day travel plan that includes local attractions, dining suggestions, accommodation recommendations plus local travel tips custom-built based on your preferences.",
          "email":"reach-out@voyayge.com",
          "sameAs" : ["https://www.instagram.com/govoyayge/","https://www.linkedin.com/company/voyay-ge-inc","https://twitter.com/voyayge"]
        }
      `}
      </script>
      {/* <meta name="theme-color" content="#fec600"></meta> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
