/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component, Fragment } from "react"
import { Helmet } from "react-helmet"
import { FirebaseContext } from "./firebase"
import { getFirebase } from "./firebase/firebase"
//import "./layout.css"
import Footer from "./footer/footer"
import Navigation from "./navigation/Navigation"
import WithAuthentication from "./session/WithAuthentication"

//Only on production
// if (process.env.NODE_ENV !== "development") {
//   if (typeof window !== "undefined") {
//     FullStory.init({ orgId: "XXWGJ", debug: false })
//   }
// }

// var authUser = ""
// if (typeof window !== "undefined") {
//   authUser = localStorage.getItem("authUser")
// }
// if (authUser) {
//   console.log("VIJAY")
// }

const loadFontAwesomeAsync = () => {
  try {
    if (typeof window !== "undefined") {
      var css = document.createElement("link")
      css.href = "https://pro.fontawesome.com/releases/v5.15.3/css/all.css"
      css.rel = "stylesheet"
      css.property = "stylesheet"
      css.type = "text/css"
      document.getElementsByTagName("head")[0].appendChild(css)
    }
  } catch (err) {}
}
class Layout extends Component {
  state = {
    firebase: null,
  }

  componentDidMount() {
    loadFontAwesomeAsync()
    let fb = getFirebase()
    this.setState({ firebase: fb }, () => {})
    // console.log(
    //   "%c firebase context updated",
    //   "color: #cC440F; font-size: 18px"
    // )
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    )
  }
}

const AppWithAuthentication = WithAuthentication(({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <link
          id="favicon"
          rel="icon"
          href="/src/images/chrome-logo-512.png"
          type="image/png"
          sizes="16x16"
        />
        {/* <link
          as="style"
          rel="preload"
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.15.3/css/all.css"
          onload="this.onload=null;this.rel='stylesheet'"
          integrity="sha384-iKbFRxucmOHIcpWdX9NTZ5WETOPm0Goy0WmfyNcl52qSYtc2Buk0NCe6jU1sWWNB"
          crossorigin="anonymous"
        ></link> */}

        {/* <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/jvectormap/2.0.4/jquery-jvectormap.css"
          type="text/css"
          media="screen"
        /> */}
        {/* <script
          src="https://fast.wistia.com/embed/medias/3ui39gxytm.jsonp"
          async
        ></script>
        <script
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
        ></script> */}
        {/* <script
          async
          defer
          src="https://static.cdn.prismic.io/prismic.js?new=true&repo=voyayge"
        ></script> */}
        {/* <script
          async
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&callback=initMap`}
        ></script> */}
      </Helmet>
      {/* <ErrorBoundary> */}
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <Navigation />
      <main className="main">
        <>
          {children}
          {/* <ShowFeedback /> */}
        </>
      </main>
      {/* <hr />
    {children} */}
      <Footer>
        © {new Date().getFullYear()},{` `}
        <a href="http://localhost:8000">Voyayge</a>
      </Footer>
      {/* </ErrorBoundary> */}
    </Fragment>
  )
})

export default Layout
