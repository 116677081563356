/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component, Fragment } from "react"
import { Helmet } from "react-helmet"
import { FirebaseContext } from "./firebase"
import { getFirebase } from "./firebase/firebase"
import WithAuthentication from "./session/WithAuthentication"

class LayoutSimple extends Component {
  state = {
    firebase: null,
  }

  componentDidMount() {
    let fb = getFirebase()
    this.setState({ firebase: fb }, () => {})
    console.log(
      "%c firebase context updated",
      "color: #cC440F; font-size: 18px"
    )
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    )
  }
}

const AppWithAuthentication = WithAuthentication(({ children }) => {
  return (
    <Fragment>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.15.3/css/all.css"
          integrity="sha384-iKbFRxucmOHIcpWdX9NTZ5WETOPm0Goy0WmfyNcl52qSYtc2Buk0NCe6jU1sWWNB"
          crossorigin="anonymous"
        ></link>
      </Helmet>
      <main className="main">
        <>
          {children}
          {/* <ShowFeedback /> */}
        </>
      </main>
    </Fragment>
  )
})

export default LayoutSimple
