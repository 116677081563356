import React, { Fragment } from "react"

import Layout from "../components/layout"
import LayoutSimple from "../components/layoutSimple"
import LostPasswordForm from "../components/LostPasswordForm/LostPasswordForm"
import SEO from "../components/seo"

const LostPassword = () => {
  React.useEffect(() => {
    document.title = "Lost password | voyayge"
  }, [])

  return (
    <Fragment>
      <LostPasswordForm />
    </Fragment>
  )
}

export default () => (
  <LayoutSimple>
    <SEO pageTitle="Forgot Password" />
    <LostPassword />
  </LayoutSimple>
)
